import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from "react";
import { Playground, Props } from "docz";
import { PackageMetadata } from "@happeo/docs-tools";
import pkg from "../package.json";
import { Tabs, SideNavigation } from "../src/index";
import { BrowserRouter } from "react-router-dom";
import { IconStar } from "@happeouikit/icons";
import menuItems from "./menuItems.js";
import ScrollExampleBox from "../src/examples/ScrollExampleBox";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "navigation"
    }}>{`Navigation`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <h2 {...{
      "id": "tabs"
    }}>{`Tabs`}</h2>
    <p>{`Tabs are used to quickly navigate between views within the same context.
No more than six tabs is recommended. This maintains an uncluttered UI and reduces cognitive load for users. If more than six tabs are needed, consider other navigation patterns, such as a side-nav.`}</p>
    <p>{`Component has following action types to do navigation:`}</p>
    <ul>
      <li parentName="ul">{`Navigate to url on click`}</li>
      <li parentName="ul">{`Execute a function on click`}</li>
      <li parentName="ul">{`Both above`}</li>
    </ul>
    <h3 {...{
      "id": "label-tab---primary---react-router-navigation"
    }}>{`Label tab - primary - react-router navigation`}</h3>
    <Playground __position={1} __code={'<BrowserRouter>\n  <Tabs>\n    <div label=\"Read\" routePath=\"/read\">\n      --- Insert ROUTE with desired component ---\n    </div>\n    <div label=\"Unread\" routePath=\"/unread\" data-tracker=\"unread\">\n      Unread\n    </div>\n    <div label=\"Settings\" routePath=\"/settings\">\n      Settings\n    </div>\n    <div label=\"Undo\" routePath=\"/undo\">\n      undooooo\n    </div>\n    <div label=\"Another\" routePath=\"/another\">\n      ANOTHER ONE\n    </div>\n  </Tabs>\n</BrowserRouter>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tabs,
      SideNavigation,
      BrowserRouter,
      IconStar,
      menuItems,
      ScrollExampleBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <BrowserRouter mdxType="BrowserRouter">
    <Tabs mdxType="Tabs">
      <div label="Read" routePath="/read">--- Insert ROUTE with desired component ---</div>
      <div label="Unread" routePath="/unread" data-tracker="unread">Unread</div>
      <div label="Settings" routePath="/settings">Settings</div>
      <div label="Undo" routePath="/undo">undooooo</div>
      <div label="Another" routePath="/another">ANOTHER ONE</div>
    </Tabs>
  </BrowserRouter>
    </Playground>
    <h3 {...{
      "id": "label-tab---primary---a-tag-href-navigation"
    }}>{`Label tab - primary - a tag href navigation`}</h3>
    <Playground __position={2} __code={'<BrowserRouter>\n  <Tabs>\n    <div label=\"Tabs\" href=\"#tabs\">\n      Tabs\n    </div>\n    <div label=\"Props\" href=\"#props\">\n      Props\n    </div>\n  </Tabs>\n</BrowserRouter>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tabs,
      SideNavigation,
      BrowserRouter,
      IconStar,
      menuItems,
      ScrollExampleBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <BrowserRouter mdxType="BrowserRouter">
      <Tabs mdxType="Tabs">
        <div label="Tabs" href="#tabs">Tabs</div>
        <div label="Props" href="#props">Props</div>
      </Tabs>
  </BrowserRouter>
    </Playground>
    <h3 {...{
      "id": "label-tab---both-url-navigation-and-custom-handler"
    }}>{`Label tab - both url navigation and custom handler`}</h3>
    <Playground __position={3} __code={'<BrowserRouter>\n  <Tabs>\n    <div\n      label=\"Read\"\n      routePath=\"/read\"\n      onCustomClick={index => console.log(\'CLICKED \', index)}\n    >\n      Read\n    </div>\n    <div\n      label=\"Unread\"\n      routePath=\"/unread\"\n      onCustomClick={index => console.log(\'CLICKED \', index)}\n    >\n      Unread\n    </div>\n  </Tabs>\n</BrowserRouter>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tabs,
      SideNavigation,
      BrowserRouter,
      IconStar,
      menuItems,
      ScrollExampleBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <BrowserRouter mdxType="BrowserRouter">
    <Tabs mdxType="Tabs">
      <div label="Read" routePath="/read" onCustomClick={index => console.log("CLICKED ", index)}>Read</div>
      <div label="Unread" routePath="/unread" onCustomClick={index => console.log("CLICKED ", index)}>Unread</div>
    </Tabs>
  </BrowserRouter>
    </Playground>
    <h3 {...{
      "id": "label-tab---add-count-near-label-and-notifications-for-primary-variant"
    }}>{`Label tab - add count near label and notifications for primary variant`}</h3>
    <Playground __position={4} __code={'<BrowserRouter>\n  <Tabs>\n    <div\n      label=\"Read\"\n      routePath=\"/read\"\n      notification={10}\n      onCustomClick={index => console.log(\'CLICKED \', index)}\n    >\n      Read\n    </div>\n    <div\n      label=\"Unread\"\n      routePath=\"/unread\"\n      count={15}\n      onCustomClick={index => console.log(\'CLICKED \', index)}\n    >\n      Unread\n    </div>\n    <div\n      label=\"Zero\"\n      routePath=\"/zero\"\n      count={0}\n      onCustomClick={index => console.log(\'CLICKED \', index)}\n    >\n      Zero\n    </div>\n  </Tabs>\n</BrowserRouter>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tabs,
      SideNavigation,
      BrowserRouter,
      IconStar,
      menuItems,
      ScrollExampleBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <BrowserRouter mdxType="BrowserRouter">
    <Tabs mdxType="Tabs">
      <div label="Read" routePath="/read" notification={10} onCustomClick={index => console.log("CLICKED ", index)}>Read</div>
      <div label="Unread" routePath="/unread" count={15} onCustomClick={index => console.log("CLICKED ", index)}>Unread</div>
      <div label="Zero" routePath="/zero" count={0} onCustomClick={index => console.log("CLICKED ", index)}>Zero</div>
    </Tabs>
  </BrowserRouter>
    </Playground>
    <h3 {...{
      "id": "label-tab---secondary-with-custom-styles"
    }}>{`Label tab - secondary with custom styles`}</h3>
    <Playground __position={5} __code={'<BrowserRouter>\n  <Tabs\n    variant=\"secondary\"\n    customStyles={{ background: \'red\', padding: \'0 16px\' }}\n  >\n    <div label=\"All\">All</div>\n    <div label=\"News\">news</div>\n    <div label=\"+ Add category...\">Settings</div>\n  </Tabs>\n</BrowserRouter>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tabs,
      SideNavigation,
      BrowserRouter,
      IconStar,
      menuItems,
      ScrollExampleBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <BrowserRouter mdxType="BrowserRouter">
    <Tabs variant="secondary" customStyles={{
          background: "red",
          padding: "0 16px"
        }} mdxType="Tabs">
      <div label="All">All</div>
      <div label="News">news</div>
      <div label="+ Add category...">Settings</div>
    </Tabs>
  </BrowserRouter>
    </Playground>
    <h3 {...{
      "id": "label-tab---secondary-with-transparent-background"
    }}>{`Label tab - secondary with transparent background`}</h3>
    <Playground __position={6} __code={'<BrowserRouter>\n  <Tabs variant=\"secondary\" transparent>\n    <div label=\"All\">All</div>\n    <div label=\"News\">news</div>\n    <div label=\"+ Add category...\">Settings</div>\n  </Tabs>\n</BrowserRouter>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tabs,
      SideNavigation,
      BrowserRouter,
      IconStar,
      menuItems,
      ScrollExampleBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <BrowserRouter mdxType="BrowserRouter">
    <Tabs variant="secondary" transparent mdxType="Tabs">
      <div label="All">All</div>
      <div label="News">news</div>
      <div label="+ Add category...">Settings</div>
    </Tabs>
  </BrowserRouter>
    </Playground>
    <h4 {...{
      "id": "label-tab-with-notification-badge"
    }}>{`Label tab with notification badge`}</h4>
    <Playground __position={7} __code={'<BrowserRouter>\n  <Tabs variant=\"secondary\">\n    <div label=\"All\" notification={9}>\n      All\n    </div>\n    <div label=\"News\" notification={10}>\n      news\n    </div>\n    <div label=\"+ Add category...\" notification={50}>\n      Settings\n    </div>\n  </Tabs>\n</BrowserRouter>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tabs,
      SideNavigation,
      BrowserRouter,
      IconStar,
      menuItems,
      ScrollExampleBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <BrowserRouter mdxType="BrowserRouter">
    <Tabs variant="secondary" mdxType="Tabs">
      <div label="All" notification={9}>
        All
      </div>
      <div label="News" notification={10}>
        news
      </div>
      <div label="+ Add category..." notification={50}>
        Settings
      </div>
    </Tabs>
  </BrowserRouter>
    </Playground>
    <h4 {...{
      "id": "label-tab-with-icon"
    }}>{`Label tab with icon`}</h4>
    <Playground __position={8} __code={'<BrowserRouter>\n  <Tabs variant=\"secondary\">\n    <div label=\"All\" icon={<IconStar />}>\n      All\n    </div>\n    <div label=\"News\" icon={<IconStar />}>\n      news\n    </div>\n    <div label=\"All news\" icon={<IconStar />}>\n      Settings\n    </div>\n  </Tabs>\n</BrowserRouter>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tabs,
      SideNavigation,
      BrowserRouter,
      IconStar,
      menuItems,
      ScrollExampleBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <BrowserRouter mdxType="BrowserRouter">
    <Tabs variant="secondary" mdxType="Tabs">
      <div label="All" icon={<IconStar mdxType="IconStar" />}>
        All
      </div>
      <div label="News" icon={<IconStar mdxType="IconStar" />}>
        news
      </div>
      <div label="All news" icon={<IconStar mdxType="IconStar" />}>
        Settings
      </div>
    </Tabs>
  </BrowserRouter>
    </Playground>
    <h4 {...{
      "id": "label-tab---oncustomclick"
    }}>{`Label tab - onCustomClick`}</h4>
    <Playground __position={9} __code={'<BrowserRouter>\n  <Tabs variant=\"secondary\">\n    <div label=\"All\" onCustomClick={index => console.log(\'CLICKED \', index)}>\n      All\n    </div>\n    <div label=\"News\" onCustomClick={index => console.log(\'CLICKED \', index)}>\n      news\n    </div>\n    <div\n      label=\"+ Add category...\"\n      onCustomClick={index => console.log(\'CLICKED \', index)}\n    >\n      Settings\n    </div>\n  </Tabs>\n</BrowserRouter>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tabs,
      SideNavigation,
      BrowserRouter,
      IconStar,
      menuItems,
      ScrollExampleBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
 <BrowserRouter mdxType="BrowserRouter">
    <Tabs variant="secondary" mdxType="Tabs">
      <div label="All" onCustomClick={index => console.log("CLICKED ", index)}>All</div>
      <div label="News" onCustomClick={index => console.log("CLICKED ", index)}>news</div>
      <div label="+ Add category..." onCustomClick={index => console.log("CLICKED ", index)}>Settings</div>
    </Tabs>
  </BrowserRouter>
    </Playground>
    <h4 {...{
      "id": "label-tab---custom-active-tab"
    }}>{`Label tab - custom active tab`}</h4>
    <Playground __position={10} __code={'() => {\n  const [active, setActive] = useState(1)\n  return (\n    <BrowserRouter>\n      <Tabs variant=\"secondary\" active={active}>\n        <div label=\"All\" onCustomClick={index => setActive(index)}>\n          All\n        </div>\n        <div label=\"News\" onCustomClick={index => setActive(index)}>\n          news\n        </div>\n        <div\n          label=\"+ Add category...\"\n          onCustomClick={index => setActive(index)}\n        >\n          Settings\n        </div>\n      </Tabs>\n      <button onClick={() => setActive(0)}>Reset filter</button>\n    </BrowserRouter>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tabs,
      SideNavigation,
      BrowserRouter,
      IconStar,
      menuItems,
      ScrollExampleBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [active, setActive] = useState(1);
        return <BrowserRouter mdxType="BrowserRouter">
          <Tabs variant="secondary" active={active} mdxType="Tabs">
            <div label="All" onCustomClick={index => setActive(index)}>All</div>
            <div label="News" onCustomClick={index => setActive(index)}>news</div>
            <div label="+ Add category..." onCustomClick={index => setActive(index)}>Settings</div>
          </Tabs>
          <button onClick={() => setActive(0)}>Reset filter</button>
        </BrowserRouter>;
      }}
    </Playground>
    <h3 {...{
      "id": "label-tab----scrolltoactive-enabled"
    }}>{`Label tab -- scrollToActive enabled`}</h3>
    <Playground __position={11} __code={'<BrowserRouter>\n  <div style={{ width: \'200px\' }}>\n    <ScrollExampleBox>\n      <Tabs scrollToActive active={5}>\n        <div label=\"page1\" routePath=\"/page1\">\n          page1\n        </div>\n        <div label=\"page2\" routePath=\"/page2\">\n          page2\n        </div>\n        <div label=\"page3\" routePath=\"/page3\">\n          page3\n        </div>\n        <div label=\"page4\" routePath=\"/page4\">\n          page4\n        </div>\n        <div label=\"page5\" routePath=\"/page5\">\n          page5\n        </div>\n        <div label=\"page6\" routePath=\"/page6\">\n          page6\n        </div>\n      </Tabs>\n    </ScrollExampleBox>\n  </div>\n</BrowserRouter>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tabs,
      SideNavigation,
      BrowserRouter,
      IconStar,
      menuItems,
      ScrollExampleBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <BrowserRouter mdxType="BrowserRouter">
    <div style={{
          width: "200px"
        }}>
      <ScrollExampleBox mdxType="ScrollExampleBox">
        <Tabs scrollToActive active={5} mdxType="Tabs">
          <div label="page1" routePath="/page1">page1</div>
          <div label="page2" routePath="/page2">page2</div>
          <div label="page3" routePath="/page3">page3</div>
          <div label="page4" routePath="/page4">page4</div>
          <div label="page5" routePath="/page5">page5</div>
          <div label="page6" routePath="/page6">page6</div>
        </Tabs>
      </ScrollExampleBox>
    </div>
  </BrowserRouter>
    </Playground>
    <h2 {...{
      "id": "tabs-props"
    }}>{`Tabs Props`}</h2>
    <Props of={Tabs} mdxType="Props" />
    <h2 {...{
      "id": "side-navigation"
    }}>{`Side Navigation`}</h2>
    <Playground __position={13} __code={'<BrowserRouter>\n  <SideNavigation menuItems={menuItems} />\n</BrowserRouter>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tabs,
      SideNavigation,
      BrowserRouter,
      IconStar,
      menuItems,
      ScrollExampleBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <BrowserRouter mdxType="BrowserRouter">
    <SideNavigation menuItems={menuItems} mdxType="SideNavigation" />
  </BrowserRouter>
    </Playground>
    <h2 {...{
      "id": "side-navigation-props"
    }}>{`Side Navigation Props`}</h2>
    <Props of={SideNavigation} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      